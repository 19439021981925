import firebase from "firebase/app";
import "firebase/storage";

// const firebaseConfig = {
//     apiKey: "AIzaSyD9b1x607WUQO3uICrkZyeV26INIWq5yy4",
//     authDomain: "perfect-app-5eef5.firebaseapp.com",
//     projectId: "perfect-app-5eef5",
//     storageBucket: "perfect-app-5eef5.appspot.com",
//     messagingSenderId: "155357764653",
//     appId: "1:155357764653:web:15c74013629e4da5236ca4"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAs87eRrCcdvcrIWfGg6zPYasJ3ttqkoHg",
  authDomain: "ferra-indica.firebaseapp.com",
  projectId: "ferra-indica",
  storageBucket: "ferra-indica.appspot.com",
  messagingSenderId: "814739989468",
  appId: "1:814739989468:web:5d5703a1ac5311615c2519",
  measurementId: "G-JVXQE3ZRTG",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
