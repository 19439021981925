import React from "react";
import "./App.css";

// import User from './components/user/User'
import { BrowserRouter, Switch, Route } from "react-router-dom";

// import leftNavigation from './components/user/LeftNavigation'
import Branch from "./components/branch/Branch";

function App(props) {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path={'/admin'} component={Admin} /> */}
        <Route path={"/"} component={Branch} />
        {/* <Route path={'/student'} component={Student} /> */}
        {/* <Route path={'/staff'} component={Staff} /> */}
        {/* <Route path={"/"} component={Website} /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
