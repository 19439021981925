import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import M, { toast } from "materialize-css";
import Config from "../../../config/Config";
import { storage } from "../../../../firebase/FirebaseConfig";
import Select from "react-select";
import Breadcrumb from "../../components/Breadcrumb";

function AddProduct() {
  const history = useHistory();
  // State Variable
  const [weight, setWeight] = useState("");
  const [mrp, setMRP] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [isAddLoaded, setIsAddLoaded] = useState(true);
  const [imageUploaded, setImageUploaded] = useState(true);
  const [defaultImages, setDefaultImages] = useState("");
  const [defaultImgProgress, setDefaultImgProgress] = useState("");
  const [bulletPoints, setBulletPoints] = useState([]);
  const [bulletPointText, setBulletPointText] = useState("");
  const [isBulletPointEditing, setIsBulletPointEditing] = useState(false);
  const [bulletPointIndex, setBulletPointIndex] = useState("");

  const [product, setProduct] = useState({
    name: "",
    slug: "",
    parentCategory: "",
    subCategory: "",
    childCategory: "",
    mrp: "",
    sellingPrice: "",
    capacity: "",
    brand: "",
    defaultImage: "",
    images: [],
    isReturnable: false,
    returnCondition: 7,
    maxOrderQuantity: 2,
  });

  const [previewImages, setPreviewImages] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);

  const [parentCategory, setParentCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [childCategory, setChildCategory] = useState([]);
  const [material, setMaterial] = useState([]);
  const [brand, setBrand] = useState([]);

  const titleChangeHandler = (evt) => {
    const value = evt.target.value;
    setProduct({
      ...product,
      slug: value.toLowerCase().replace(/\s+/g, "-"),
      name: value,
    });
  };

  // Image Change
  const imageChangeHandler = (event, type) => {
    if (type == "default_image") {
      handleUpload(event.target.files[0], "", type);
    } else {
      if (event.target.files && event.target.files.length) {
        [...event.target.files].map((value, index) => {
          handleUpload(value, index);
        });
      }
    }
  };

  // File Delete Handler
  const fileDeleteHandler = (image, index, type) => {
    // Create a reference to the file to delete
    const fileRef = storage.refFromURL(image);
    // Delete the file
    fileRef
      .delete()
      .then(() => {
        // File deleted successfully
        if (type == "default_image") {
          setDefaultImages("");
          setDefaultImgProgress("");
        } else {
          let pImages = [...previewImages];
          pImages.splice(index, 1);

          let pInfos = [...progressInfos];
          pInfos.splice(index, 1);
          setProgressInfos(pInfos);
          setPreviewImages(pImages);
        }
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        M.toast({ html: error, classes: "bg-danger" });
      });
  };

  // Upload Image
  const handleUpload = (image, i, type) => {
    const uploadTask = storage.ref(`products/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        if (type == "default_image") {
          setDefaultImgProgress(progress);
        } else {
          let arrs = [...progressInfos];
          arrs[i] = progress;
          setProgressInfos((old) => {
            return [...arrs];
          });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("products")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            if (type == "default_image") {
              setDefaultImages(url);
              setProduct((old) => {
                return {
                  ...old,
                  defaultImage: url,
                };
              });
            } else {
              setPreviewImages((old) => [...old, url]);
              setProduct((old) => {
                return {
                  ...old,
                  images: [...old.images, { url }],
                };
              });
            }
          });
      }
    );
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    const addProduct = {
      ...product,
      bulletPoints: bulletPoints || [],
    };

    fetch(Config.SERVER_URL + "/products", {
      method: "POST",
      body: JSON.stringify(addProduct),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Parent Category
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/parentCategories?skip=0&limit=5000`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setParentCategory(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Sub Category
  useEffect(() => {
    let url = `${Config.SERVER_URL}/subCategories`;
    let body = "";
    let method = "GET";
    if (product.parentCategory) {
      url = `${Config.SERVER_URL}/subCategories?skip=0&limit=0&parCatId=${product.parentCategory}`;
    }

    fetch(url, {
      method: method,
      body: body != "" ? JSON.stringify(body) : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            // if (!result.body.length) setSelectSCat([]);
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setCategory(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [product.parentCategory]);

  // get Child Category
  useEffect(() => {
    let url = `${Config.SERVER_URL}/childCategories?skip=0&limit=0`;
    if (product.subCategory) {
      url = `${Config.SERVER_URL}/childCategories?skip=0&limit=0&subCatId=${product.subCategory}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setChildCategory(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [product.subCategory]);

  // get Materials
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/materials?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let modifyForSelect = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setMaterial(modifyForSelect);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Brand
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/brands?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let modifyForSelect = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setBrand(modifyForSelect);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // Add bullet points handler
  const addBulletPointHandler = (evt) => {
    evt.preventDefault();
    if (bulletPointText) {
      setBulletPoints((old) => {
        return [...old, bulletPointText];
      });
      setBulletPointText("");
    } else {
      M.toast({ html: "Please Write Somthing !", classes: "bg-danger" });
    }
  };

  // Delete bullet points handler
  const deleteBulletPointHandler = (index) => {
    let allPoints = [...bulletPoints];
    allPoints.splice(index, 1);
    setBulletPoints((old) => {
      return [...allPoints];
    });
  };

  // Update bullet points handler
  const updateBulletPointHandler = (evt) => {
    evt.preventDefault();
    let allPoints = [...bulletPoints];
    allPoints[bulletPointIndex] = bulletPointText;
    setBulletPoints((old) => {
      return [...allPoints];
    });
    setIsBulletPointEditing(false);
    setBulletPointText("");
  };

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}

        <Breadcrumb
          title="PRODUCTS"
          page="PRODUCT"
          pageLink={"/products"}
          subPage={"ADD"}
          goBack={true}
        />

        {/* End Bread crumb and right sidebar toggle */}

        {/* Listing Form */}
        <div className="row mt-2">
          <div className={"col-md-12 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* PRODUCT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT DETAILS</h3>
                </div>

                {/* PRODUCT NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT NAME !
                  </label>
                  <input
                    type="text"
                    value={product.name}
                    onChange={titleChangeHandler}
                    className="form-control"
                    placeholder={"chakla belan with stand"}
                  />
                </div>

                {/* PRODUCT SLUG */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SLUG !
                  </label>
                  <input
                    type="text"
                    value={product.slug}
                    onChange={(evt) =>
                      setProduct({ ...product, slug: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"chakla-belan-with-stand"}
                  />
                </div>

                {/* PRODUCT SKU */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SKU !
                  </label>
                  <input
                    type="text"
                    value={product.sku}
                    onChange={(evt) =>
                      setProduct({ ...product, sku: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"PSHK2873"}
                  />
                </div>

                {/* PRODUCT STOCK*/}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT STOCK!
                  </label>
                  <input
                    type="number"
                    value={product.stock}
                    onChange={(evt) =>
                      setProduct({ ...product, stock: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"100"}
                  />
                </div>

                {/* PRODUCT LOW STOCK AMOUNT*/}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT LOW STOCK AMOUNT!
                  </label>
                  <input
                    type="number"
                    value={product.lowStockAmount}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        lowStockAmount: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"7"}
                  />
                </div>

                {/* PRODUCT WEIGHT*/}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT WEIGHT!
                  </label>
                  <input
                    type="text"
                    value={product.weight}
                    onChange={(evt) =>
                      setProduct({ ...product, weight: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"1KG"}
                  />
                </div>

                {/* PRODUCT LENGTH */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT LENGTH !
                  </label>
                  <input
                    type="text"
                    value={product.length}
                    onChange={(evt) =>
                      setProduct({ ...product, length: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"100 CM"}
                  />
                </div>

                {/* PRODUCT WIDTH */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT WIDTH !
                  </label>
                  <input
                    type="text"
                    value={product.width}
                    onChange={(evt) =>
                      setProduct({ ...product, width: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"50 CM"}
                  />
                </div>

                {/* PRODUCT HEIGHT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT HEIGHT !
                  </label>
                  <input
                    type="text"
                    value={product.height}
                    onChange={(evt) =>
                      setProduct({ ...product, height: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"10 CM"}
                  />
                </div>

                {/* PRODUCT DIAMETER */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT DIAMETER !
                  </label>
                  <input
                    type="text"
                    value={product.diameter}
                    onChange={(evt) =>
                      setProduct({ ...product, diameter: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"10 CM"}
                  />
                </div>

                {/* PRODUCT CAPACITY */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT CAPACITY !
                  </label>
                  <input
                    type="text"
                    value={product.capacity}
                    onChange={(evt) =>
                      setProduct({ ...product, capacity: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"110 ML"}
                  />
                </div>

                {/*PACKAGE CONTENT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PACKAGE CONTENT !
                  </label>
                  <input
                    type="text"
                    value={product.packageContent}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        packageContent: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"1 Item"}
                  />
                </div>

                {/*MAXIMUM ORDER QUANTITY */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAXIMUM ORDER QUANTITY !
                  </label>
                  <input
                    type="text"
                    value={product.maxOrderQuantity}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        maxOrderQuantity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"1 Item"}
                  />
                </div>

                {/*BUTTON TEXT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    BUTTON TEXT !
                  </label>
                  <input
                    type="text"
                    value={product.buttonText}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        buttonText: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Add To Cart"}
                  />
                </div>

                {/* PILE HEIGHT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PILE HEIGHT !
                  </label>
                  <input
                    type="text"
                    value={product.pileHeight}
                    onChange={(evt) =>
                      setProduct({ ...product, pileHeight: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"THIK"}
                  />
                </div>

                {/* MOUNTING TYPE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MOUNTING TYPE !
                  </label>
                  <input
                    type="text"
                    value={product.mountingType}
                    onChange={(evt) =>
                      setProduct({ ...product, mountingType: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"DRY MOUNT"}
                  />
                </div>

                {/* CARE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CARE !
                  </label>
                  <input
                    type="text"
                    value={product.care}
                    onChange={(evt) =>
                      setProduct({ ...product, care: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"CARE"}
                  />
                </div>

                {/* PRODUCT COLOR */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT COLOR !
                  </label>
                  <input
                    type="text"
                    value={product.color}
                    onChange={(evt) =>
                      setProduct({ ...product, color: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"green"}
                  />
                </div>

                {/* MAKE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAKE !
                  </label>
                  <input
                    type="text"
                    value={product.make}
                    onChange={(evt) =>
                      setProduct({ ...product, make: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Houseware"}
                  />
                </div>

                {/* PRODUCT MRP */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT MRP !
                  </label>
                  <input
                    type="number"
                    value={product.mrp}
                    onChange={(evt) =>
                      setProduct({ ...product, mrp: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"5000"}
                  />
                </div>

                {/* PRODUCT SELLING PRICE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SELLING PRICE !
                  </label>
                  <input
                    type="number"
                    value={product.sellingPrice}
                    onChange={(evt) =>
                      setProduct({ ...product, sellingPrice: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"4500"}
                  />
                </div>

                {/* PRODUCT SHAPE*/}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SHAPE !
                  </label>
                  <input
                    type="text"
                    value={product.shape}
                    onChange={(evt) =>
                      setProduct({ ...product, shape: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"4500"}
                  />
                </div>

                {/* PARENT CATEGORY */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT PARENT CATEGORY !
                  </label>
                  <div className="">
                    <Select
                      options={parentCategory}
                      // onChange={addParentCategoryHandler}
                      onChange={(evt) => {
                        setProduct({ ...product, parentCategory: evt.value });
                      }}
                    />
                  </div>
                </div>

                {/* SELECT SUB CATEGORY */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT SUB CATEGORY !
                  </label>

                  <div className="">
                    <Select
                      options={category}
                      // onChange={addSubCategoryHandler}
                      onChange={(evt) => {
                        setProduct({ ...product, subCategory: evt.value });
                      }}
                    />
                  </div>
                </div>

                {/* SELECT CHILD CATEGORY */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT CHILD CATEGORY !
                  </label>

                  <div className="">
                    <Select
                      options={childCategory}
                      // onChange={addSubCategoryHandler}
                      onChange={(evt) => {
                        setProduct({ ...product, childCategory: evt.value });
                      }}
                    />
                  </div>
                </div>

                {/* Brand */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT BRAND !
                  </label>

                  <Select
                    options={brand}
                    onChange={(evt) => {
                      setProduct({ ...product, brand: evt.value });
                    }}
                  />
                </div>

                {/* Select Material */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT MATERIAL !
                  </label>

                  <div className="">
                    <Select
                      options={material}
                      onChange={(evt) => {
                        setProduct({ ...product, material: evt.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* PRODUCT DESCRIPTION */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT DESCRIPTION</h3>
                </div>
                <div className={"form-group col-md-12"}>
                  <CKEditor
                    editor={ClassicEditor}
                    style={{ height: "100px" }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setProduct({ ...product, description: data });
                    }}
                    data={product.description}
                  />
                </div>
              </div>

              {/* PRODUCT RETURN DETAILS */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT RETURN DETAILS</h3>
                </div>

                {/* IS PRODUCT RETURNABLE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    IS PRODUCT RETURNABLE !
                  </label>

                  <div className="d-flex">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="discountType1"
                        name="discountType"
                        value={true}
                        checked={product.isReturnable == true ? true : false}
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            isReturnable: Boolean(evt.target.value),
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="discountType1"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="discountType2"
                        name="discountType"
                        value={false}
                        checked={product.isReturnable == false ? true : false}
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            isReturnable: Boolean(evt.target.value),
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="discountType2"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                {/* RETURN CONDITION */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    RETURN CONDITION IN DAYS !
                  </label>
                  <input
                    type="number"
                    value={product.returnCondition}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        returnCondition: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"7"}
                  />
                </div>
              </div>

              {/* PRODUCT BULLET POINTS */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT BULLET POINTS</h3>
                </div>

                {/* IS PRODUCT RETURNABLE */}
                <div className={"form-group col-md-10"}>
                  <label htmlFor="" className="text-dark h6 active">
                    ADD POINTS !
                  </label>
                  <div className="d-flex justify-content-between">
                    <input
                      type="text"
                      value={bulletPointText}
                      onChange={(evt) => setBulletPointText(evt.target.value)}
                      className="form-control"
                      placeholder={"Write points"}
                    />
                    {isBulletPointEditing ? (
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={updateBulletPointHandler}
                      >
                        <i className="fa fa-pencil"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={addBulletPointHandler}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    )}
                  </div>
                </div>

                <div className={"form-group col-md-10 mt-2"}>
                  <label htmlFor="" className="text-dark h6 active mb-3">
                    ADDED POINTS !
                  </label>
                  <ul className="list-group">
                    {bulletPoints.map((item, index) => {
                      return (
                        <li
                          className="list-group-item py-1 d-flex justify-content-between"
                          key={index}
                        >
                          {item}

                          <div className="">
                            <button
                              className="btn btn-info"
                              type="button"
                              onClick={(evt) => {
                                setBulletPointText(item);
                                setIsBulletPointEditing(true);
                                setBulletPointIndex(index);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={(evt) => {
                                deleteBulletPointHandler(index);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {/* SEO DETAILS */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>SEO DETAILS</h3>
                </div>

                {/* META TITLE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    META TITLE !
                  </label>
                  <input
                    type="text"
                    value={product.metaTitle}
                    onChange={(evt) =>
                      setProduct({ ...product, metaTitle: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Product Meta Title"}
                  />
                </div>

                {/* META KEYWORDS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    META KEYWORDS !
                  </label>
                  <input
                    type="text"
                    value={product.metaKeywords}
                    onChange={(evt) =>
                      setProduct({ ...product, metaKeywords: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Write Meta Keywords"}
                  />
                </div>

                {/* PRODUCT META DESCRIPTION */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    META DESCRIPTION !
                  </label>
                  <textarea
                    type="text"
                    value={product.metaDescription}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        metaDescription: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Write Meta Description"}
                    rows={3}
                  ></textarea>
                </div>
              </div>

              {/* PRODUCT IMAGES */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT IMAGES</h3>
                </div>

                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT DEFAULT IMAGE
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(evt) => imageChangeHandler(evt, "default_image")}
                    className="form-control"
                  />
                </div>

                <div className="col-md-6">
                  {defaultImages ? (
                    <div className={"form-group"}>
                      <img
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          border: "1px solid #5a5a5a",
                        }}
                        src={defaultImages}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "40%",
                          right: "45%",
                        }}
                        type="button"
                        className="btn bg-light text-danger"
                        title={"Delete Image"}
                        onClick={(evt) =>
                          fileDeleteHandler(defaultImages, "", "default_image")
                        }
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {defaultImgProgress ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${defaultImgProgress}%` }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {defaultImgProgress}%
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* Products Multiple Images */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT IMAGES
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={imageChangeHandler}
                    className="form-control"
                  />
                </div>

                {/*Multiple Image Preview */}
                <div className="col-md-12 mb-1">
                  <div className="row">
                    {previewImages.map((img, index) => {
                      return (
                        <div className={"form-group col-md-3"} key={index}>
                          <img
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              border: "1px solid #5a5a5a",
                            }}
                            src={img}
                          />
                          <button
                            style={{
                              position: "absolute",
                              top: "40%",
                              right: "45%",
                            }}
                            type="button"
                            className="btn bg-light text-danger"
                            title={"Delete Image"}
                            onClick={(evt) => fileDeleteHandler(img, index)}
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Multiple image prpgress */}
                <div className="col-md-12 mb-2">
                  <div className="row">
                    {progressInfos.map((info, index) => {
                      return (
                        <div className="col-md-3" key={index}>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${info}%` }}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {info}%
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Submit Button */}
                <div className={"form-group col-md-6"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isAddLoaded ? (
                      <div>
                        <i className="fas fa-plus"></i> Add Product
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
