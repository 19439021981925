import React from "react";
import Papa from "papaparse";
import { useState } from "react";
import Config from "../../../config/Config";
import M from "materialize-css";
import tableToCSV from "../../helpers";
import Breadcrumb from "../../components/Breadcrumb";

const EditProductFromCSV = () => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploaded, setUploaded] = useState([]);
  const [isAllRecordLoaded, setIsAllRecordLoaded] = useState(true);

  const fileChangeHandler = (event) => {
    const files = event.target.files;
    if (files) {
      Papa.parse(event.target.files[0], {
        complete: async (results) => {
          let keys = results.data[0];
          // I want to remove some óíúáé, blan spaces, etc
          keys = results.data[0].map((v) =>
            v
              // .toLowerCase()
              .replace(/ /g, "_")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
          let values = results.data.slice(1);
          let objects = values.map((array) => {
            let object = {};
            keys.forEach((key, i) => (object[key] = array[i]));
            return object;
          });
          // Now I call to my API and everything goes ok

          // Get data from array and call the api
          objects.map((item, i) => {
            if (item.id != "") {
              item.images = item.images.split("__").map((item) => {
                return { url: item };
              });

              item.bulletPoints = item.bulletPoints.split("__");
              item.isReturnable = item.isReturnable.toLowerCase();
              item.status = item.status.toLowerCase();

              // item.slug = item.name
              //   .toLowerCase()
              //   .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
              //   .replace(/\s+/g, "-");
              submitHandler(item);
            }
          });
        },
      });

      // Papa.parse(files[0], {
      //   complete: function (results) {
      //     console.log("Finished:", results.data);
      //     insertDataHandler({ name: results.data });
      //   },
      // });
    }
  };

  // Update Submit Handler
  const submitHandler = (item) => {
    const id = item.id;
    item.id = undefined;
    fetch(`${Config.SERVER_URL}/products/${id}`, {
      method: "PUT",
      body: JSON.stringify(item),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            // M.toast({ html: result.message, classes: "bg-success" });
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
          }
          setUploaded((old) => {
            return [
              ...old,
              {
                name: result.body.name || "",
                message: result.message || result.errors.message,
              },
            ];
          });
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  const makeElement = (elemName, innerText = null, row = null) => {
    const elem = document.createElement(elemName);
    if (innerText) {
      elem.innerHTML = innerText;
    }
    if (row) {
      row.appendChild(elem);
    }
    return elem;
  };

  const downloadCSVHandler = () => {
    let table = makeElement("table");
    table.setAttribute("id", "download-csv");
    let thead = makeElement("thead");
    table.appendChild(thead);

    let row = makeElement("tr");
    makeElement("th", "id", row);
    makeElement("th", "name", row);
    makeElement("th", "slug", row);
    makeElement("th", "mrp", row);
    makeElement("th", "sellingPrice", row);
    makeElement("th", "sku", row);
    makeElement("th", "stock", row);
    makeElement("th", "lowStockAmount", row);
    makeElement("th", "weight", row);
    makeElement("th", "length", row);
    makeElement("th", "width", row);
    makeElement("th", "height", row);
    makeElement("th", "diameter", row);
    makeElement("th", "capacity", row);
    makeElement("th", "packageContent", row);
    makeElement("th", "pileHeight", row);
    makeElement("th", "mountingType", row);
    makeElement("th", "brand", row);
    makeElement("th", "care", row);
    makeElement("th", "color", row);
    makeElement("th", "description", row);
    makeElement("th", "make", row);
    makeElement("th", "material", row);
    makeElement("th", "shape", row);
    makeElement("th", "bulletPoints", row);
    makeElement("th", "isReturnable", row);
    makeElement("th", "returnCondition", row);
    makeElement("th", "maxOrderQuantity", row);
    makeElement("th", "buttonText", row);
    makeElement("th", "metaTitle", row);
    makeElement("th", "metaDescription", row);
    makeElement("th", "metaKeywords", row);

    makeElement("th", "parentCategory", row);
    makeElement("th", "subCategory", row);
    makeElement("th", "childCategory", row);
    makeElement("th", "defaultImage", row);
    makeElement("th", "images", row);
    makeElement("th", "status", row);
    thead.appendChild(row);
    // Load Data from the Database
    setIsAllRecordLoaded(false);
    fetch(`${Config.SERVER_URL}/products?skip=0&limit=0&status=All`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAllRecordLoaded(true);
          if (result.status === 200) {
            result.body.map((item, index) => {
              let dataRow = document.createElement("tr");
              makeElement("td", item._id.toString(), dataRow);
              makeElement("td", item.name, dataRow);
              makeElement("td", item.slug, dataRow);
              makeElement("td", item.mrp, dataRow);
              makeElement("td", item.sellingPrice, dataRow);
              makeElement("td", item.sku, dataRow);
              makeElement("td", item.stock, dataRow);
              makeElement("td", item.lowStockAmount, dataRow);
              makeElement("td", item.weight, dataRow);
              makeElement("td", item["length"], dataRow);
              makeElement("td", item.width, dataRow);
              makeElement("td", item.height, dataRow);
              makeElement("td", item.diameter, dataRow);
              makeElement("td", item.capacity, dataRow);
              makeElement("td", item.packageContent, dataRow);
              makeElement("td", item.pileHeight, dataRow);
              makeElement("td", item.mountingType, dataRow);
              makeElement("td", item.brand ? item.brand._id : "", dataRow);
              makeElement("td", item.care, dataRow);
              makeElement("td", item.color, dataRow);
              makeElement("td", item.description, dataRow);
              makeElement("td", item.make, dataRow);
              makeElement(
                "td",
                item.material ? item.material._id : "",
                dataRow
              );
              makeElement("td", item.shape, dataRow);
              makeElement("td", item.bulletPoints.join("__"), dataRow);
              makeElement("td", item.isReturnable.toString(), dataRow);
              makeElement("td", item.returnCondition, dataRow);
              makeElement("td", item.maxOrderQuantity, dataRow);
              makeElement("td", item.buttonText, dataRow);
              makeElement("td", item.metaTitle, dataRow);
              makeElement("td", item.metaDescription, dataRow);
              makeElement("td", item.metaKeywords, dataRow);
              makeElement(
                "td",
                item.parentCategory ? item.parentCategory._id : "",
                dataRow
              );
              makeElement(
                "td",
                item.subCategory ? item.subCategory._id : "",
                dataRow
              );
              makeElement(
                "td",
                item.childCategory ? item.childCategory._id : "",
                dataRow
              );

              makeElement("td", item.defaultImage, dataRow);
              let images = item.images.map((item) => {
                return item.url;
              });
              makeElement("td", images.join("__"), dataRow);

              makeElement("td", item.status.toString(), dataRow);

              thead.appendChild(dataRow);
            });
            tableToCSV("products.csv", table);
            // setAllRecords(result.body || []);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsAllRecordLoaded(true);
        }
      );
  };

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb
          title="PRODUCTS"
          page="PRODUCT"
          pageLink={"/products"}
          subPage={"EDIT"}
          goBack={true}
        />

        {/* Add Color Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              //   onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/*  */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12 d-flex justify-content-between">
                  <h3 className={"my-3 text-info"}>Upload CSV File</h3>
                  <div className="">
                    <button
                      onClick={downloadCSVHandler}
                      className="btn btn-info"
                      type="button"
                    >
                      {isAllRecordLoaded ? (
                        <span>
                          <i className="fa fa-download"></i> Download CSV Format
                        </span>
                      ) : (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading..
                        </div>
                      )}
                    </button>
                  </div>
                </div>

                {/* File */}
                <div className={"form-group col-md-6"}>
                  <input
                    type="file"
                    onChange={fileChangeHandler}
                    className="form-control"
                    placeholder={"Range Name"}
                  />
                </div>
                <div className={"form-group col-md-6"}>
                  {uploadLoading ? (
                    <div className={"bg-white p-3 text-center"}>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-11 mx-auto">
            <div className={"row shadow-sm bg-white py-3"}>
              <div className="col-md-12">
                {uploaded.map((item, index) => {
                  return (
                    <div className="card card-body">
                      {" "}
                      {item.name} {item.message}{" "}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductFromCSV;
