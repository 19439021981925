import React, {
  Fragment,
  createContext,
  useReducer,
  useContext,
  useEffect,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import LeftNavigation from "./LeftNavigation";
import TopNavigation from "./TopNavigation";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { initialState, branchReducer } from "../../reducer/branchReducer";
import Profile from "./pages/Profile";
import PageNoteFound from "./pages/PageNotFound";

import Category from "./pages/Category";

// ================   Products  =====================
import AddProduct from "./pages/products/AddProduct";
import ProductList from "./pages/products/ProductList";

// ================   Coupons  =====================
import AddCoupon from "./pages/coupons/AddCoupon";
import CouponList from "./pages/coupons/CouponList";
import EditCoupon from "./pages/coupons/EditCoupon";

import ColorList from "./pages/colors/ColorList";
import AddColor from "./pages/colors/AddColor";
import EditColor from "./pages/colors/EditColor";
import EditProduct from "./pages/products/EditProduct";

import PincodeList from "./pages/pincode/PincodeList";
import AddPincode from "./pages/pincode/AddPincode";
import EditPincode from "./pages/pincode/EditPincode";

import CustomerList from "./pages/customers/CustomerList";
import NewOrders from "./pages/orders/NewOrders";
import ViewOrder from "./pages/orders/ViewOrder";
import OrderList from "./pages/orders/OrderList";

import GuestOrderList from "./pages/guestOrder/GuestOrderList";

import Setting from "./pages/setting/Setting";
import MainSlider from "./pages/banners/MainSlider";
import NextToSlider from "./pages/banners/NextToSlider";
import DailyBestSaleBanner from "./pages/banners/DailyBestSaleBanner";
import CategoryBanner from "./pages/banners/CategoryBanner";
import OfferBanner from "./pages/banners/OfferBanner";
import ParentCategoryList from "./pages/parentCategory/ParentCategoryList";
import AddParentCategory from "./pages/parentCategory/AddParentCategory";
import EditParentCategory from "./pages/parentCategory/EditParentCategory";
import SubCategoryList from "./pages/subCategory/SubCategoryList";
import AddCouponFromCSV from "./pages/coupons/AddCouponFromCSV";
import EditCouponFromCSV from "./pages/coupons/EditCouponFromCSV";

import AddColorFromCSV from "./pages/colors/AddColorFromCSV";
import EditColorFromCSV from "./pages/colors/EditColorFromCSV";
import EditPincodeFromCSV from "./pages/pincode/EditPincodeFromCSV";
import AddPincodeFromCSV from "./pages/pincode/AddPincodeFromCSV";
import AddParentCategoryFromCSV from "./pages/parentCategory/AddParentCategoryFromCSV";
import EditParentCategoryFromCSV from "./pages/parentCategory/EditParenrtCategortyFromCSV";

import CustomerReports from "./pages/reports/CustomerReports";
import ProductReports from "./pages/reports/ProductReports";
import EditCustomer from "./pages/customers/EditCustomer";
import ViewCustomer from "./pages/customers/ViewCustomer";
import AddSubCategory from "./pages/subCategory/AddSubCategory";
import EditSubCategory from "./pages/subCategory/EditSubCategory";
import AddSubCategoryFromCSV from "./pages/subCategory/AddSubCategoryFromCSV";
import EditSubCategoryFromCSV from "./pages/subCategory/EditSubCategoryFromCSV";
import ChildCategoryList from "./pages/childCategory/ChildCategoryList";
import AddChildCategory from "./pages/childCategory/AddChildCategory";
import EditChildCategory from "./pages/childCategory/EditChildCategory";
import AddChildCategoryFromCSV from "./pages/childCategory/AddChildCategoryFromCSV";
import EditChildCategoryFromCSV from "./pages/childCategory/EditChildCategoryFromCSV";
import MaterialList from "./pages/material/MaterialList";
import AddMaterialFromCSV from "./pages/material/AddMaterialFromCSV";
import EditMaterialFromCSV from "./pages/material/EditMaterialFromCSV";
import AddMaterial from "./pages/material/AddMaterial";
import EditMaterial from "./pages/material/EditMaterial";
import SizeList from "./pages/size/SizeList";
import AddSizeFromCSV from "./pages/size/AddSizeFromCSV";
import EditSizeFromCSV from "./pages/size/EditSizeFromCSV";
import AddSize from "./pages/size/AddSize";
import EditSize from "./pages/size/EditSize";
import NewsletterList from "./pages/newsletters/NewsletterList";
import AddNewsletterFromCSV from "./pages/newsletters/AddNewsletterFromCSV";
import EditNewsletterFromCSV from "./pages/newsletters/EditNewsletterFromCSV";
import AddNewsletter from "./pages/newsletters/AddNewsletter";
import EditNewsletter from "./pages/newsletters/EditNewsletter";
import PostCategoryList from "./pages/blogPostCategory/PostCategoryList";
import AddPostCategoryFromCSV from "./pages/blogPostCategory/AddPostCategoryFromCSV";
import EditPostCategortyFromCSV from "./pages/blogPostCategory/EditPostCategortyFromCSV";
import AddPostCategory from "./pages/blogPostCategory/AddPostCategory";
import EditPostCategory from "./pages/blogPostCategory/EditPostCategory";
import AuthorList from "./pages/authors/AuthorList";
import AddAuthorFromCSV from "./pages/authors/AddAuthorFromCSV";
import EditAuthorFromCSV from "./pages/authors/EditAuthorFromCSV";
import AddAuthor from "./pages/authors/AddAuthor";
import EditAuthor from "./pages/authors/EditAuthor";
import TestimonialList from "./pages/testimonials/TestimonialList";
import AddTestimonialFromCSV from "./pages/testimonials/AddTestimonialFromCSV";
import EditTestimonialFromCSV from "./pages/testimonials/EditTestimonialFromCSV";
import AddTestimonial from "./pages/testimonials/AddTestimonial";
import EditTestimonial from "./pages/testimonials/EditTestimonial";
import BlogPostList from "./pages/blogPosts/BlogPostList";
import AddBlogPost from "./pages/blogPosts/AddBlogPost";
import EditBlogPost from "./pages/blogPosts/EditBlogPost";
import InquiryList from "./pages/inquiries/InquiryList";
import AddInquiryFromCSV from "./pages/inquiries/AddInquiryFromCSV";
import EditInquiryFromCSV from "./pages/inquiries/EditInquiryFromCSV";
import AddInquiry from "./pages/inquiries/AddInquiry";
import EditInquiry from "./pages/inquiries/EditInquiry";
import AddProductFromCSV from "./pages/products/AddProductFromCSV";
import EditProductFromCSV from "./pages/products/EditProductFromCSV";
import ShapeList from "./pages/shapes/ShapeList";
import AddShapeFromCSV from "./pages/shapes/AddShapeFromCSV";
import EditShapeFromCSV from "./pages/shapes/EditShapeFromCSV";
import AddShape from "./pages/shapes/AddShape";
import EditShape from "./pages/shapes/EditShape";
import BrandList from "./pages/brands/BrandList";
import AddBrandFromCSV from "./pages/brands/AddBrandFromCSV";
import EditBrandFromCSV from "./pages/brands/EditBrandFromCSV";
import AddBrand from "./pages/brands/AddBrand";
import EditBrand from "./pages/brands/EditBrand";
import ForgotPassword from "./pages/ForgotPassword";
import EnterOtp from "./pages/EnterOtp";
import CreateNewPassword from "./pages/CreateNewPassword";
import BreadcrumbBanner from "./pages/banners/BreadcrumbBanner";
import CloneProduct from "./pages/products/CloneProduct";
import ViewGuestOrder from "./pages/guestOrder/ViewGuestOrder";
import NewGuestOrders from "./pages/guestOrder/NewGuestOrders";
import FalseGuestOrder from "./pages/guestOrder/FalseGuestOrder";
import CartProductReports from "./pages/reports/CartProductReports";

// Create Context
export const BranchContext = createContext();

// Create Context
const Routing = () => {
  const history = useHistory();
  // Branch Context
  const { state, dispatch } = useContext(BranchContext);
  useEffect(() => {
    const branch = JSON.parse(localStorage.getItem("branch"));
    if (branch) {
      dispatch({ type: "BRANCH", payload: branch });
      // history.push("/")
    } else {
      // history.push("/login");
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/enter-otp" component={EnterOtp} />
      <Route exact path="/create-password" component={CreateNewPassword} />
      <Route exact path="/profile" component={Profile} />

      {/* Parent Category */}
      <Route exact path="/parentCategories" component={ParentCategoryList} />
      <Route exact path="/parentCategory/add" component={AddParentCategory} />
      <Route
        exact
        path="/parentCategory/addByCSV"
        component={AddParentCategoryFromCSV}
      />
      <Route
        exact
        path="/parentCategory/editByCSV"
        component={EditParentCategoryFromCSV}
      />
      <Route
        exact
        path="/parentCategory/edit/:id"
        component={EditParentCategory}
      />

      {/* <Route exact path="/parentCategory" component={ParentCategory} /> */}

      {/* Sub Category */}

      <Route exact path="/category" component={Category} />
      <Route exact path="/categories" component={SubCategoryList} />
      <Route exact path="/categories/add" component={AddSubCategory} />
      <Route exact path="/categories/edit/:id" component={EditSubCategory} />
      <Route
        exact
        path="/categories/addByCSV"
        component={AddSubCategoryFromCSV}
      />
      <Route
        exact
        path="/categories/editByCSV"
        component={EditSubCategoryFromCSV}
      />

      {/* Child Category */}
      <Route exact path="/childCategories" component={ChildCategoryList} />
      <Route exact path="/childCategory/add" component={AddChildCategory} />
      <Route
        exact
        path="/childCategory/edit/:id"
        component={EditChildCategory}
      />
      <Route
        exact
        path="/childCategory/addByCSV"
        component={AddChildCategoryFromCSV}
      />
      <Route
        exact
        path="/childCategory/editByCSV"
        component={EditChildCategoryFromCSV}
      />

      {/* Products */}
      <Route exact path="/products" component={ProductList} />
      <Route exact path="/product/add" component={AddProduct} />
      <Route exact path="/product/edit/:id" component={EditProduct} />
      <Route exact path="/product/clone/:id" component={CloneProduct} />
      <Route exact path="/product/addByCSV" component={AddProductFromCSV} />
      <Route exact path="/product/editByCSV" component={EditProductFromCSV} />

      {/* Coupons */}
      <Route exact path="/coupons" component={CouponList} />
      <Route exact path="/coupon/add" component={AddCoupon} />
      <Route exact path="/coupon/addByCSV" component={AddCouponFromCSV} />
      <Route exact path="/coupon/editByCSV" component={EditCouponFromCSV} />
      <Route exact path="/coupon/edit/:id" component={EditCoupon} />

      {/* Pincode */}
      <Route exact path="/pincodes" component={PincodeList} />
      <Route exact path="/pincode/add" component={AddPincode} />
      <Route exact path="/pincode/addByCSV" component={AddPincodeFromCSV} />
      <Route exact path="/pincode/editByCSV" component={EditPincodeFromCSV} />
      <Route exact path="/pincode/edit/:id" component={EditPincode} />

      {/* Colors */}
      <Route exact path="/colors" component={ColorList} />
      <Route exact path="/color/addByCSV" component={AddColorFromCSV} />
      <Route exact path="/color/editByCSV" component={EditColorFromCSV} />
      <Route exact path="/color/add" component={AddColor} />
      <Route exact path="/color/edit/:id" component={EditColor} />

      {/* Material */}
      <Route exact path="/materials" component={MaterialList} />
      <Route exact path="/material/addByCSV" component={AddMaterialFromCSV} />
      <Route exact path="/material/editByCSV" component={EditMaterialFromCSV} />
      <Route exact path="/material/add" component={AddMaterial} />
      <Route exact path="/material/edit/:id" component={EditMaterial} />

      {/* Shapes */}
      <Route exact path="/shapes" component={ShapeList} />
      <Route exact path="/shape/addByCSV" component={AddShapeFromCSV} />
      <Route exact path="/shape/editByCSV" component={EditShapeFromCSV} />
      <Route exact path="/shape/add" component={AddShape} />
      <Route exact path="/shape/edit/:id" component={EditShape} />

      {/* Brands */}
      <Route exact path="/brands" component={BrandList} />
      <Route exact path="/brand/addByCSV" component={AddBrandFromCSV} />
      <Route exact path="/brand/editByCSV" component={EditBrandFromCSV} />
      <Route exact path="/brand/add" component={AddBrand} />
      <Route exact path="/brand/edit/:id" component={EditBrand} />

      {/* Inquiries */}
      <Route exact path="/inquiries" component={InquiryList} />
      <Route exact path="/inquiry/addByCSV" component={AddInquiryFromCSV} />
      <Route exact path="/inquiry/editByCSV" component={EditInquiryFromCSV} />
      <Route exact path="/inquiry/add" component={AddInquiry} />
      <Route exact path="/inquiry/edit/:id" component={EditInquiry} />

      {/* Size */}
      <Route exact path="/sizes" component={SizeList} />
      <Route exact path="/size/addByCSV" component={AddSizeFromCSV} />
      <Route exact path="/size/editByCSV" component={EditSizeFromCSV} />
      <Route exact path="/size/add" component={AddSize} />
      <Route exact path="/size/edit/:id" component={EditSize} />

      {/* Newsletters */}
      <Route exact path="/newsletters" component={NewsletterList} />
      <Route
        exact
        path="/newsletter/addByCSV"
        component={AddNewsletterFromCSV}
      />
      <Route
        exact
        path="/newsletter/editByCSV"
        component={EditNewsletterFromCSV}
      />
      <Route exact path="/newsletter/add" component={AddNewsletter} />
      <Route exact path="/newsletter/edit/:id" component={EditNewsletter} />

      {/* Blog Post Category */}
      <Route exact path="/blogPostCategories" component={PostCategoryList} />
      <Route
        exact
        path="/blogPostCategory/addByCSV"
        component={AddPostCategoryFromCSV}
      />
      <Route
        exact
        path="/blogPostCategory/editByCSV"
        component={EditPostCategortyFromCSV}
      />
      <Route exact path="/blogPostCategory/add" component={AddPostCategory} />
      <Route
        exact
        path="/blogPostCategory/edit/:id"
        component={EditPostCategory}
      />

      {/* Orders */}
      <Route exact path="/newOrders" component={NewOrders} />
      <Route exact path="/order/show/:id" component={ViewOrder} />
      <Route exact path="/orders" component={OrderList} />

      {/* Guest Orders */}

      <Route exact path="/newGuestOrders" component={NewGuestOrders} />
      <Route exact path="/falseGuestOrders" component={FalseGuestOrder} />
      <Route exact path="/guestOrders" component={GuestOrderList} />
      <Route exact path="/guestOrder/show/:id" component={ViewGuestOrder} />

      {/* Reports */}
      <Route exact path="/report/customers" component={CustomerReports} />
      <Route exact path="/report/products" component={ProductReports} />
      <Route exact path="/report/cartProducts" component={CartProductReports} />

      {/* Customer */}
      <Route exact path="/customers" component={CustomerList} />
      <Route exact path="/customer/edit/:id" component={EditCustomer} />
      <Route exact path="/customer/show/:id" component={ViewCustomer} />

      {/* Authors*/}
      <Route exact path="/authors" component={AuthorList} />
      <Route exact path="/author/addByCSV" component={AddAuthorFromCSV} />
      <Route exact path="/author/editByCSV" component={EditAuthorFromCSV} />
      <Route exact path="/author/add" component={AddAuthor} />
      <Route exact path="/author/edit/:id" component={EditAuthor} />

      {/* Testimonials*/}
      <Route exact path="/testimonials" component={TestimonialList} />
      <Route
        exact
        path="/testimonial/addByCSV"
        component={AddTestimonialFromCSV}
      />
      <Route
        exact
        path="/testimonial/editByCSV"
        component={EditTestimonialFromCSV}
      />
      <Route exact path="/testimonial/add" component={AddTestimonial} />
      <Route exact path="/testimonial/edit/:id" component={EditTestimonial} />

      {/* Blog Posts*/}
      <Route exact path="/blogPosts" component={BlogPostList} />
      <Route
        exact
        path="/blogPost/addByCSV"
        component={AddTestimonialFromCSV}
      />
      <Route
        exact
        path="/blogPost/editByCSV"
        component={EditTestimonialFromCSV}
      />
      <Route exact path="/blogPost/add" component={AddBlogPost} />
      <Route exact path="/blogPost/edit/:id" component={EditBlogPost} />

      {/* Settings */}
      <Route exact path="/setting" component={Setting} />

      {/* Images */}
      <Route exact path="/slider" component={MainSlider} />
      <Route exact path="/nextToSlider" component={NextToSlider} />
      <Route exact path="/bestSaleBanner" component={DailyBestSaleBanner} />
      <Route exact path="/categoryBanner" component={CategoryBanner} />
      <Route exact path="/breadcrumbBanner" component={BreadcrumbBanner} />
      <Route exact path="/offerBanner" component={OfferBanner} />

      {/* Page Not Found */}
      <Route exact path="/*" component={PageNoteFound} />
    </Switch>
  );
};

const Branch = () => {
  const [state, dispatch] = useReducer(branchReducer, initialState);
  return (
    <div id="main-wrapper">
      <BranchContext.Provider value={{ state: state, dispatch: dispatch }}>
        <Router>
          <TopNavigation />
          <LeftNavigation />
          <Routing />
        </Router>
      </BranchContext.Provider>
    </div>
  );
};

export default Branch;
