import React, { useState, useEffect } from "react";
import M from "materialize-css";
import Config from "../../../config/Config";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";

const Setting = () => {
  const [isUpdateLoaded, setIsUpdateLoaded] = useState(true);

  const [setting, setSetting] = useState({});
  const [contactUs, setContactUs] = useState({});
  const [socialMedia, setSocialMedia] = useState({});
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [deliveryCharge, setDeliveryCharge] = useState([]);

  // Submit Handler
  const submitHandler = (evt) => {
    setIsUpdateLoaded(false);
    evt.preventDefault();

    const updateData = {
      cashback: setting.cashback || undefined,
      maximumCashbackAmount: setting.maximumCashbackAmount || undefined,
      minimumOrderAmount: setting.minimumOrderAmount || undefined,
      deliveryCharge: deliveryCharge || [],
      cashbackStatus: setting.cashbackStatus || false,
      contactUs: {
        mobile: contactUs.mobile || undefined,
        customerCareNumber: contactUs.customerCareNumber || undefined,
        whatsappNumber: contactUs.whatsappNumber || undefined,
        email: contactUs.email || undefined,
        supportEmail: contactUs.supportEmail || undefined,
        address: contactUs.address || undefined,
        googleMapUrl: contactUs.googleMapUrl || undefined,
      },
      socialMedia: {
        facebook: socialMedia.facebook || undefined,
        twitter: socialMedia.twitter || undefined,
        instagram: socialMedia.instagram || undefined,
        youtube: socialMedia.youtube || undefined,
      },
    };

    fetch(`${Config.SERVER_URL}/settings`, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsUpdateLoaded(true);
        },
        (error) => {
          setIsUpdateLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Records
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/settings/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setSetting(result.body);
            setContactUs(result.body.contactUs || {});
            setSocialMedia(result.body.socialMedia || {});
            setDeliveryCharge(result.body.deliveryCharge || []);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  const addDeliveryHandler = (evt) => {
    setDeliveryCharge((old) => {
      return [...old, deliveryDetails];
    });
    setDeliveryDetails({});
  };

  const deleteDeliveryHandler = (index) => {
    const delivery = [...deliveryCharge];
    delivery.splice(index, 1);

    setDeliveryCharge([...delivery]);
    setDeliveryDetails({});
  };

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}

        <Breadcrumb
          title="SETTINGS"
          page="UPDATE SETTING"
          pageLink={"/setting"}
          subPage={""}
          goBack={true}
        />

        {/* End Bread crumb and right sidebar toggle */}

        {/* Add Setting Form */}
        <div className="row mt-2">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* ORDER DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>ORDER DETAILS</h3>
                </div>

                {/*  MINIMUM ORDER AMOUNT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MINIMUM ORDER AMOUNT
                  </label>
                  <input
                    type="text"
                    value={setting.minimumOrderAmount}
                    onChange={(evt) =>
                      setSetting({
                        ...setting,
                        minimumOrderAmount: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Standard Delivery"}
                  />
                </div>
              </div>

              {/* DELIVERY DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>DELIVERY DETAILS</h3>
                </div>

                {/*  DELIVERY CHARGE */}
                <div className={"form-group col-md-4"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DELIVERY CHARGE
                  </label>
                  <input
                    type="text"
                    value={deliveryDetails.charge || ""}
                    onChange={(evt) =>
                      setDeliveryDetails({
                        ...deliveryDetails,
                        charge: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"100"}
                  />
                </div>

                {/*  MAXIMUM AMOUNT */}
                <div className={"form-group col-md-4"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAXIMUM DELIVERY AMOUNT ({"<="})
                  </label>
                  <input
                    type="text"
                    value={deliveryDetails.maxAmount || ""}
                    onChange={(evt) =>
                      setDeliveryDetails({
                        ...deliveryDetails,
                        maxAmount: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"999"}
                  />
                </div>

                {/*  ADD BUTTON*/}
                <div className={"form-group col-md-4"}>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={addDeliveryHandler}
                  >
                    Add Delivery Charge
                  </button>
                </div>

                <div className="col-md-12">
                  <table className="table table-bordered">
                    <tr>
                      <th>DELIVERY CHARGE</th>
                      <th>MAXIMUM DELIVERY AMOUNT ({"<="})</th>
                    </tr>
                    {deliveryCharge.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.charge}</td>
                          <td>{item.maxAmount}</td>
                          <td>
                            {" "}
                            <button
                              onClick={() => {
                                deleteDeliveryHandler(index);
                              }}
                              className="btn btn-danger"
                              type="button"
                            >
                              Delete
                            </button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>

              {/* CONTACT US DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>CONTACT US DETAILS</h3>
                </div>

                {/*  MOBILE NUMBER */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MOBILE NUMBER
                  </label>
                  <input
                    type="text"
                    value={contactUs.mobile}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        mobile: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Mobile Number"}
                  />
                </div>

                {/* CUSTOMER CARE NUMBER */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CUSTOMER CARE NUMBER
                  </label>
                  <input
                    type="text"
                    value={contactUs.customerCareNumber}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        customerCareNumber: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Customer Care Number"}
                  />
                </div>

                {/*  WHATSAPP NUMBER */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    WHATSAPP NUMBER
                  </label>
                  <input
                    type="text"
                    value={contactUs.whatsappNumber}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        whatsappNumber: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Whatsapp Number"}
                  />
                </div>

                {/* INFORMATION EMAIL */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    INFORMATION EMAIL
                  </label>
                  <input
                    type="email"
                    value={contactUs.email}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        email: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Info Email"}
                  />
                </div>

                {/* SUPPORT EMAIL */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SUPPORT EMAIL
                  </label>
                  <input
                    type="email"
                    value={contactUs.supportEmail}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        supportEmail: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Support Email"}
                  />
                </div>

                {/* ADDRESS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    ADDRESS
                  </label>
                  <input
                    type="text"
                    value={contactUs.address}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        address: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Address"}
                  />
                </div>

                {/* GOOGLE MAP URL */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    GOOGLE MAP URL
                  </label>
                  <input
                    type="text"
                    value={contactUs.googleMapUrl}
                    onChange={(evt) =>
                      setContactUs({
                        ...contactUs,
                        googleMapUrl: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={
                      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6173744.205570939!2d82.24481834291522!3d20.903283504650048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe4e50a1d1e293926!2sFerra%20Indica!5e0!3m2!1sen!2sin!4v1662980796958!5m2!1sen!2sin"
                    }
                  />
                </div>
              </div>

              {/* SOCIAL MEDIAL DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>SOCIAL MEDIAL DETAILS</h3>
                </div>

                {/*  FACEBOOK */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FACEBOOK
                  </label>
                  <input
                    type="url"
                    value={socialMedia.facebook}
                    onChange={(evt) =>
                      setSocialMedia({
                        ...socialMedia,
                        facebook: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Facebook URL"}
                  />
                </div>

                {/*  TWITTER */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    TWITTER
                  </label>
                  <input
                    type="url"
                    value={socialMedia.twitter}
                    onChange={(evt) =>
                      setSocialMedia({
                        ...socialMedia,
                        twitter: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Twitter URL"}
                  />
                </div>

                {/*  INSTAGRAM */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    INSTAGRAM
                  </label>
                  <input
                    type="url"
                    value={socialMedia.instagram}
                    onChange={(evt) =>
                      setSocialMedia({
                        ...socialMedia,
                        instagram: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Instagram URL"}
                  />
                </div>
                {/*  YOUTUBE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    YOUTUBE
                  </label>
                  <input
                    type="url"
                    value={socialMedia.youtube}
                    onChange={(evt) =>
                      setSocialMedia({
                        ...socialMedia,
                        youtube: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Youtube URL"}
                  />
                </div>

                <div className={"form-group col-md-12 mt-2"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isUpdateLoaded ? (
                      <div>
                        <i className="fas fa-edit"></i> Update Setting
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>

              {/* Cashback Details */}
              {/* <div className={"row shadow-sm bg-white py-3 mt-2"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>Cashback Details</h3>
                </div>

                <div className="form-group col-md-12">
                  <div className="form-check m-0 p-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={setting.cashbackStatus}
                      onChange={(evt) => {
                        setSetting({
                          ...setting,
                          cashbackStatus: evt.target.checked,
                        });
                      }}
                      id="useWallet"
                    />
                    <label className="form-check-label" for="useWallet">
                      Cashback Status
                    </label>
                  </div>
                </div>

        
                {setting.cashbackStatus && (
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      Maximum Cashback Amount
                    </label>
                    <input
                      type="text"
                      value={setting.maximumCashbackAmount}
                      onChange={(evt) =>
                        setSetting({
                          ...setting,
                          maximumCashbackAmount: evt.target.value,
                        })
                      }
                      className="form-control"
                      placeholder={"Ex: 100"}
                    />
                  </div>
                )}

            
                {setting.cashbackStatus && (
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      Cashback Percentage
                    </label>
                    <input
                      type="number"
                      value={setting.cashback}
                      onChange={(evt) =>
                        setSetting({
                          ...setting,
                          cashback: evt.target.value,
                        })
                      }
                      className="form-control"
                      placeholder={2}
                    />
                  </div>
                )}

                
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
