import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import { storage } from "../../../../firebase/FirebaseConfig";

const AddCoupon = () => {
  const history = useHistory();
  const [isAddLoaded, setIsAddLoaded] = useState(true);
  const [defaultImages, setDefaultImages] = useState("");
  const [defaultImgProgress, setDefaultImgProgress] = useState("");

  const [coupon, setCoupon] = useState({
    code: "",
    applyFor: "",
    discountType: "",
    discount: "",
    description: "",
    usesTimes: 1,
    minimumAmount: "",
    startDate: "",
    image: "",
    validity: "",
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    fetch(Config.SERVER_URL + "/coupons", {
      method: "POST",
      body: JSON.stringify(coupon),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // Image Change
  const imageChangeHandler = (event, type) => {
    if (type == "default_image") {
      handleUpload(event.target.files[0], "", type);
    } else {
      if (event.target.files && event.target.files.length) {
        [...event.target.files].map((value, index) => {
          handleUpload(value, index);
        });
      }
    }
  };

  // File Delete Handler
  const fileDeleteHandler = (image) => {
    setDefaultImages("");
    setDefaultImgProgress("");
    // Create a reference to the file to delete
    // const fileRef = storage.refFromURL(image);

    // fileRef
    //   .delete()
    //   .then(() => {
    //     // File deleted successfully
    //     setDefaultImages("");
    //     setDefaultImgProgress("");
    //   })
    //   .catch((error) => {
    //     // Uh-oh, an error occurred!
    //     M.toast({ html: error, classes: "bg-danger" });
    //   });
  };

  // Upload Image
  const handleUpload = (image) => {
    const uploadTask = storage.ref(`products/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        setDefaultImgProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("products")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            setDefaultImages(url);
            setCoupon((old) => {
              return {
                ...old,
                image: url,
              };
            });
          });
      }
    );
  };

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}

        <Breadcrumb
          title="COUPONS"
          page="COUPON"
          pageLink={"/coupons"}
          subPage={"ADD"}
          goBack={true}
        />

        {/* Add Coupon Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Coupon Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>Coupon Details</h3>
                </div>

                {/* Coupon Code */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COUPON CODE HERE !
                  </label>
                  <input
                    type="text"
                    value={coupon.code}
                    onChange={(evt) =>
                      setCoupon({ ...coupon, code: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Coupon Code"}
                  />
                </div>

                {/* Apply For */}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT USER TYPE!
                  </label>
                  <div className="d-flex mt-2">
                    <div className="custom-control custom-radio pl-0">
                      <input
                        type="radio"
                        id="userType1"
                        name="userType"
                        value={"NEW_USER"}
                        onChange={(evt) =>
                          setCoupon({ ...coupon, applyFor: evt.target.value })
                        }
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" for="userType1">
                        New User
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="userType2"
                        name="userType"
                        onChange={(evt) =>
                          setCoupon({ ...coupon, applyFor: evt.target.value })
                        }
                        value={"EXISTING_USER"}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" for="userType2">
                        Existing User
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="userType3"
                        name="userType"
                        onChange={(evt) =>
                          setCoupon({ ...coupon, applyFor: evt.target.value })
                        }
                        value={"ALL_USER"}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" for="userType3">
                        All User
                      </label>
                    </div>
                  </div>
                </div>

                {/* Discount type*/}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT DISCOUNT TYPE!
                  </label>
                  <div className="d-flex">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="discountType1"
                        name="discountType"
                        value={"AMOUNT"}
                        onChange={(evt) =>
                          setCoupon({
                            ...coupon,
                            discountType: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="discountType1"
                      >
                        AMOUNT
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="discountType2"
                        name="discountType"
                        value={"PERCENTAGE"}
                        onChange={(evt) =>
                          setCoupon({
                            ...coupon,
                            discountType: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="discountType2"
                      >
                        PRECENTAGE
                      </label>
                    </div>
                  </div>
                </div>

                {/* Discount aomunt */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DISCOUNT AMOUNT!
                  </label>
                  <input
                    type="number"
                    value={coupon.discount}
                    onChange={(evt) =>
                      setCoupon({ ...coupon, discount: evt.target.value })
                    }
                    name={"discount"}
                    className="form-control"
                    placeholder={"Discount Amount"}
                  />
                </div>

                {/* minimum Amount */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MINIMUM AMOUNT FOR APPLYING COUPON
                  </label>
                  <input
                    type="number"
                    value={coupon.minimumAmount}
                    onChange={(evt) =>
                      setCoupon({ ...coupon, minimumAmount: evt.target.value })
                    }
                    name={"minimumAmount"}
                    className="form-control"
                    placeholder={"Minimum Amount For Coupon"}
                  />
                </div>

                {/* start Date */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COUPON ACTIVATION DATE
                  </label>
                  <input
                    type="date"
                    value={coupon.startDate}
                    onChange={(evt) =>
                      setCoupon({ ...coupon, startDate: evt.target.value })
                    }
                    name={"startDate"}
                    className="form-control"
                    placeholder={"Startting date"}
                  />
                </div>

                {/* Valid Upto */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COUPON EXPIRY DATE
                  </label>
                  <input
                    type="date"
                    value={coupon.validity}
                    onChange={(evt) =>
                      setCoupon({ ...coupon, validity: evt.target.value })
                    }
                    name={"startDate"}
                    className="form-control"
                    placeholder={"Valid Upto"}
                  />
                </div>

                {/* Uses Times */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    USES TIMES FOR USER
                  </label>
                  <input
                    type="number"
                    value={coupon.usesTimes}
                    onChange={(evt) =>
                      setCoupon({ ...coupon, usesTimes: evt.target.value })
                    }
                    name={"startDate"}
                    className="form-control"
                    placeholder={"Valid Upto"}
                  />
                </div>
              </div>

              {/* Coupon Description */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>Coupon Description</h3>
                </div>
                <div className={"form-group col-md-12"}>
                  <CKEditor
                    editor={ClassicEditor}
                    style={{ height: "100px" }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setCoupon({ ...coupon, description: data });
                    }}
                    data={coupon.description}
                  />
                </div>
              </div>

              {/* Coupon Image */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>COUPON IMAGE</h3>
                </div>

                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    Select Image File
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(evt) => imageChangeHandler(evt)}
                    className="form-control"
                  />
                </div>

                <div className="col-md-6">
                  {defaultImages ? (
                    <div className={"form-group"}>
                      <img
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          border: "1px solid #5a5a5a",
                        }}
                        src={defaultImages}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "40%",
                          right: "45%",
                        }}
                        type="button"
                        className="btn bg-light text-danger"
                        title={"Delete Image"}
                        onClick={(evt) => fileDeleteHandler(defaultImages)}
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {defaultImgProgress ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${defaultImgProgress}%` }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {defaultImgProgress}%
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className={"form-group col-md-12"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isAddLoaded ? (
                      <div>
                        <i className="fas fa-plus"></i> Add Coupon
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCoupon;
