import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";

const ViewOrder = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isUpdateLoaded, setIsUpdateLoaded] = useState(true);
  const [showCancelInput, setShowCancelInput] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [order, setOrder] = useState({
    products: [],
    billingAddress: {},
    shippingAddress: {},
    adonProducts: [],
    shippingMethod: {
      startTime: "16:00",
      endTime: "16:00",
    },
    orderStatus: "ORDERPLACED",
    coupon: {},
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsUpdateLoaded(false);
    evt.preventDefault();

    const updateData = {
      orderStatus: order.orderStatus,
    };

    if (order.orderStatus == "CANCELLED") {
      updateData.cancelledBy = "ADMIN";
    }
    if (order.cancelMessage != "")
      updateData.cancelMessage = order.cancelMessage;

    fetch(`${Config.SERVER_URL}/orders/${order.id}`, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.error);
            errorKeys.forEach((key) => {
              M.toast({ html: result.error[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsUpdateLoaded(true);
        },
        (error) => {
          setIsUpdateLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Records
  useEffect(() => {
    setDataLoading(false);
    fetch(`${Config.SERVER_URL}/orders/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            console.log(result.body);
            setOrder({ ...result.body, coupon: result.body.coupon });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setDataLoading(true);
        },
        (error) => {
          setDataLoading(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb
          title="ORDER DETAILS"
          page="ORDER LIST"
          pageLink={"/orders"}
          subPage={"VIEW"}
          goBack={true}
        />

        {/* Add order Form */}
        <div className="row">
          {dataLoading ? (
            <div className={"col-md-11 mx-auto"}>
              {/* order Details */}
              <div className={"row shadow-sm bg-white py-3 px-3"}>
                <div className="col-md-12 d-flex justify-content-between my-3">
                  <div className="">
                    <h5>
                      Order Id:
                      <span className={"font-weight-bold"}>{order._id}</span>
                    </h5>
                  </div>
                  {/* <!-- Button trigger modal --> */}

                  <div className="form-inline">
                    <select
                      className="form-control shadow-sm rounded"
                      onChange={(evt) => {
                        setOrder({ ...order, orderStatus: evt.target.value });
                      }}
                      onClick={(evt) => {
                        evt.preventDefault();
                        if (order.orderStatus == "CANCELLED") {
                          setShowCancelInput(true);
                        } else {
                          setShowCancelInput(false);
                        }
                      }}
                      value={order.orderStatus}
                    >
                      <option value="ORDERPLACED">ORDER PLACED</option>
                      <option value="CONFIRMED">CONFIRMED</option>
                      <option value="DISPATCHED">DISPATCHED</option>
                      <option value="DELIVERED">DELIVERED</option>
                      <option value="CANCELLED">CANCELLED</option>
                      <option value="RETURNED">RETURNED</option>
                    </select>
                    {showCancelInput ? (
                      <div className="ml-2">
                        <input
                          type="text"
                          value={order.cancelMessage}
                          onChange={(evt) =>
                            setOrder({
                              ...order,
                              cancelMessage: evt.target.value,
                            })
                          }
                          className="form-control shadow-sm ml-4"
                          placeholder="Reason For Cancel"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn btn-info ml-2"
                      onClick={submitHandler}
                    >
                      Update
                    </button>
                  </div>
                </div>

                {/* order Code */}
                <div className={"col-md-12"}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>PRODUCT</th>
                        <th>Size & Color</th>

                        <th>QTY</th>
                        <th>PRICE</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.products.map((product, index) => {
                        return (
                          <tr>
                            <td> {++index} </td>
                            <td>
                              <h6> {product.name} </h6>
                              <img
                                className="m-auto"
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "50px",
                                }}
                                src={`${product.image}`}
                                alt=""
                              />
                            </td>

                            <td>
                              Color:
                              {product.color}
                              <br /> Material: {product.material.name}
                            </td>

                            <td>{product.quantity}</td>
                            <td>
                              <span className="fa fa-inr"></span>
                              {product.price}
                            </td>
                            <td>
                              <span className="fa fa-inr"></span>
                              {product.quantity * product.price}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td colSpan={5}>Sub Total</td>
                        <td>
                          <span className="fa fa-inr"></span>
                          {order.subtotal}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          Discount With Coupon
                          {
                            <span className="badge badge-success">
                              {order.coupon.code || ""}
                            </span>
                          }
                        </td>
                        <td>
                          <span className="fa fa-inr"></span>
                          {order.discountWithCoupon || "0.00"}
                        </td>
                      </tr>

                      {order?.specialDiscountAmount ? (
                        <tr>
                          <td colSpan={5}>
                            Special Discount{" "}
                            {order?.specialDiscount?.discount || ""}
                            {
                              <span className="badge badge-success">
                                {order?.specialDiscount?.message || ""}
                              </span>
                            }
                          </td>
                          <td>
                            <span className="fa fa-inr"></span>
                            {order.specialDiscountAmount || "0.00"}
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td colSpan={5}>Delivery Charge</td>
                        <td>
                          <span className="fa fa-inr"></span>
                          {order.deliveryAmount || 0}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>Total Amount</td>
                        <td>
                          <span className="fa fa-inr"></span>
                          {order.totalAmount || "FREE"}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              {/* order Description */}
              <div className={"row mt-3 py-3"}>
                <div className="col-md-4 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h3 className={"my-3 text-info"}> Order Status </h3>
                      <div className="">
                        <h5>
                          {order.orderStatus === "ORDERPLACED" ? (
                            <span className="badge badge-info">
                              {order.orderStatus}
                            </span>
                          ) : order.orderStatus === "CONFIRMED" ? (
                            <span className="badge badge-warning">
                              {order.orderStatus}
                            </span>
                          ) : order.orderStatus === "DISPATCHED" ? (
                            <span className="badge badge-primary">
                              {order.orderStatus}
                            </span>
                          ) : order.orderStatus === "DELIVERED" ? (
                            <span className="badge badge-success">
                              {order.orderStatus}
                            </span>
                          ) : order.orderStatus === "CANCELLED" ? (
                            <span className="badge badge-danger">
                              {order.orderStatus}
                            </span>
                          ) : order.orderStatus === "RETURNED" ? (
                            <span className="badge badge-danger">
                              {order.orderStatus}
                            </span>
                          ) : (
                            ""
                          )}
                        </h5>
                        {order.cancelMessage ? (
                          <h5> Msg: {order.cancelMessage} </h5>
                        ) : (
                          ""
                        )}
                        {order.orderStatus == "CANCELLED" ? (
                          <h5> Cancelled By: {order.cancelledBy} </h5>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h3 className={"my-3 text-info"}> Payment Status </h3>
                      <div className="">
                        <h5>
                          {order.paymentMethod == "COD" ? (
                            <span className="badge badge-info">
                              {order.paymentMethod}
                            </span>
                          ) : (
                            <div>
                              <span className="badge badge-success">
                                {order.paymentMethod}
                              </span>
                              <p>
                                Rzp Order Id:{" "}
                                <span className="text-dark font-weight-bold">
                                  {order.razorpayOrderId}
                                </span>
                              </p>
                              <p>
                                Rzp Payment Id:{" "}
                                <span className="text-dark font-weight-bold">
                                  {order.razorpayPaymentId}
                                </span>{" "}
                              </p>
                            </div>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 px-1">
                  <div className="card">
                    <div className="card-body">
                      <h3 className={"my-3 text-info"}> Shipping Details </h3>
                      <div className="">
                        <h5> {order.shippingAddress.name} </h5>
                        <h6> {order.shippingAddress.email} </h6>
                        <h6> {order.shippingAddress.mobile} </h6>
                        <h6>Address : {order.shippingAddress.address}</h6>
                        <h6>Landmark : {order.shippingAddress.landmark}</h6>
                        <h6> Pincode: {order.shippingAddress.pincode} </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-11 m-auto">
              <div className={"bg-white p-3 text-center"}>
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading..
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
